import {isUndefined} from '@ivosabev/helpers/isUndefined';
import {type SubmitOptions, useSubmit} from '@remix-run/react';
import {useMemo} from 'react';
import {useToastAdvanced} from '#/app/hooks/useToastAdvanced.js';

const safeValue = (v: any): string => (typeof v === 'object' ? JSON.stringify(v) : String(v));

export const useSubmitAdvanced = () => {
  const submitRemix = useSubmit();
  const toast = useToastAdvanced();

  return useMemo(() => {
    const submit = (actionType: string | undefined, target?: Record<string, any>, options?: SubmitOptions) => {
      toast.clean();

      const initTarget: {actionType: string} | {} = !isUndefined(actionType) ? {actionType} : {};
      const safeTarget = Object.keys(target || {}).reduce((p, c) => ({
        ...p,
        [c]: safeValue((target || {})[c]),
      }), initTarget);

      submitRemix(safeTarget, {
        // action: window.location.href,
        encType: 'multipart/form-data',
        method: options?.method || 'post',
        ...options,
      });
    };

    return {submit, toast};
  }, [submitRemix, toast]);
};
